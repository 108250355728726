import React, { Component } from 'react'

export default class Contact extends Component {
  render() {
    return (
      <div className="py-10 py-lg-20 bg-white">
        <div className="container">
          <div className="text-center mb-12">
            <h2
              className="mb-5"
              id="contact"
              data-kt-scroll-offset="{default: 100, lg: 150}"
            >
              CONTACT
            </h2>
            <hr />
          </div>
          <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h4 className="m-b-0">
                    Please email queries to{' '}
                    <u>
                      <a href="mailto:ir@avolon.aero">ir@avolon.aero</a>
                    </u>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
            <div className="col-md-6 col-lg-4 col-xl-4">
              <div className="card border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3 card-stretch">
                <div className="card-body d-flex justify-content-center text-left flex-column p-8">
                  <p className="text-dark fw-bold mb-4">Dublin</p>
                  <p>
                    Number One Ballsbridge
                    <br /> Building 1<br /> Shelbourne Road
                    <br /> Dublin 4<br /> D04 FP65
                    <br /> Ireland
                    <br />
                    <br />
                    <br /> <a href="tel:35312315800">+353 1 231 5800</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4">
              <div className="card border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3 card-stretch">
                <div className="card-body d-flex justify-content-center text-left flex-column p-8">
                  <p className="text-dark fw-bold mb-4">Florida</p>
                  <p>
                    900 South Pine Island Road
                    <br /> Suite 200
                    <br /> FL 33324
                    <br /> USA
                    <br />
                    <br />
                    <br />
                    <br />
                    <br /> <a href="tel:35312315800"> +1 954 233 2132 </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4">
              <div className="card border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3 card-stretch">
                <div className="card-body d-flex justify-content-center text-left flex-column p-8">
                  <p className="text-dark fw-bold mb-4">Dubai</p>
                  <p>
                    Unit 1106
                    <br /> Level 11,
                    <br /> Index Tower, DIFC
                    <br /> Dubai, UAE
                    <br />
                    <br />
                    <br />
                    <br />
                    <br /> <a href="tel:+971504550981">+971 56 9966003 </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4">
              <div className="card border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3 card-stretch">
                <div className="card-body d-flex justify-content-center text-left flex-column p-8">
                  <p className="text-dark fw-bold mb-4">Singapore</p>
                  <p>
                    138 Market Street
                    <br /> CapitaGreen #33-03
                    <br /> Singapore 048945
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br /> <a href="tel:+6566317996"> +65 6631 7996 </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4">
              <div className="card border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3 card-stretch">
                <div className="card-body d-flex justify-content-center text-left flex-column p-8">
                  <p className="text-dark fw-bold mb-4">Hong Kong</p>
                  <p>
                    Suite 7809-13, 78/F
                    <br /> The Center
                    <br /> 99 Queen’s Road Central, Central
                    <br /> Hong Kong
                    <br /> China
                    <br />
                    <br />
                    <br />
                    <br /> <a href="tel:+85225680010"> +852 2568 0010 </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
