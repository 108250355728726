import React from 'react'
import CustomSvg from './/common/CustomSvg'
import Backend from '../../utils/Backend'
import Notify from '../../utils/Notify'
export default class FileItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      url: props.url,
      slug: props.slug,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  _fileDownloaded() {
    let { url, slug } = this.state
    if (!slug) {
      return
    }
    Backend.updateDownloadCount(slug)
      .then(() => {
        // do nothing
      })
      .catch((err) => {
        Notify.error(err.message)
      })
  }

  render() {
    let { url } = this.state
    if (!url) {
      return '-'
    }
    let fileType = url.split('.').pop()
    if (!url.includes('cloudfront')) {
      fileType = 'link'
    }
    return (
      <a
        href={url}
        target="_blank"
        onClick={() => this._fileDownloaded()}
        className={'text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'}
      >
        <div className={'symbol symbol-50px me-5'}>
          <CustomSvg text={fileType} />
        </div>
      </a>
    )
  }
}
