import runtimeEnv from '@mars/heroku-js-runtime-env'

const env = runtimeEnv()

window.Api = {}

window.Api.Base = env.REACT_APP_API_BASE

window.Api.RecaptchaSiteKey           = env.REACT_APP_RECAPTCHA_SITE_KEY

window.Api.User = {}

window.Api.User.Base = window.Api.Base + '/user'

window.Api.User.Login = window.Api.User.Base + '/login'
window.Api.User.Logout = window.Api.User.Base + '/logout'
window.Api.Register = window.Api.Base + '/investor'
window.Api.User.Info = window.Api.User.Base + '/info'
window.Api.Hashes = window.Api.Base + '/hashes'
window.Api.User.RequestResetPassword =
  window.Api.User.Base + '/requestResetPassword'
window.Api.User.ResetPassword = window.Api.User.Base + '/resetPassword'
window.Api.User.RefreshToken = window.Api.User.Base + '/login/refresh'

window.Api.Menu = window.Api.Base + '/pages/menu'
window.Api.Footer = window.Api.Base + '/pages/footer'
window.Api.CreditRatings = window.Api.Base + '/creditRatings'
window.Api.FinancialStats = window.Api.Base + '/financialStats'
window.Api.Reports = window.Api.Base + '/reports'
window.Api.Stats = window.Api.Base + '/stats'
window.Api.UpcomingResults = window.Api.Base + '/upcomingResults'
window.Api.Mail = window.Api.Base + '/mails'
window.Api.Sections = window.Api.Base + '/investorSections'


