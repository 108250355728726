import React, { Component } from 'react'
import { Navigation } from 'react-minimal-side-navigation'
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'
import AuthManager from '../../utils/AuthManager'
import Backend from '../../utils/Backend'

import runtimeEnv from '@mars/heroku-js-runtime-env'

const env = runtimeEnv()
export default class Menu extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this._loadMenu()
  }

  _loadMenu() {
    Backend.getMenu()
      .then((response) => {
        this.setState({ menuData: response })
      })
      .catch((err) => {
        console.log('menu 45', err)
      })
  }

  _scrollToSection(id) {
    const section = document.getElementById(id)
    section.scrollIntoView({ behavior: 'smooth' })
  }

  _renderMenuItems() {
    let { menuData } = this.state
    if (!menuData) {
      return null
    }
    return menuData.map((item, index) => {
      return this._renderMenuItem(item, index)
    })
  }

  _renderMenuItem(item, index) {
    return (
      <div className="menu-item">
        <a
          href={env.REACT_APP_WEBSITE_DOMAIN + '/' + item.slug}
          className="menu-link nav-link py-3 px-4 px-xxl-6 font-weight-boldest"
          style={{ fontWeight: 'bolder' }}
          data-kt-scroll-toggle="true"
          data-kt-drawer-dismiss="true"
        >
          {item.title}
        </a>
      </div>
    )
  }

  render() {
    let { menuData } = this.state
    if (!menuData) {
      return null
    }
    return (
      <div
        className="landing-header"
        data-kt-sticky="true"
        data-kt-sticky-name="landing-header"
        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
        style={{ animationDuration: '0.3s' }}
      >
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-equal">
              <button
                className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none"
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                id="kt_landing_menu_toggle"
              >
                <span className="svg-icon svg-icon-2hx">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                      fill="black"
                    />
                    <path
                      opacity="0.3"
                      d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </button>
              <a href="javascript:;">
                <img
                  alt="Logo"
                  src="assets/media/logos/avolon-logo.png"
                  className="logo-default h-25px h-lg-30px"
                />
                <img
                  alt="Logo"
                  src="assets/media/logos/avolon-logo.png"
                  className="logo-sticky h-20px h-lg-25px"
                />
              </a>
            </div>
            <div className="d-lg-block" id="kt_header_nav_wrapper">
              <div
                className={
                  this.state.isOpen
                    ? 'd-lg-block p-5 p-lg-0 drawer drawer-start drawer-on'
                    : 'd-lg-block p-5 p-lg-0'
                }
                data-kt-drawer="true"
                data-kt-drawer-name="landing-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="200px"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_landing_menu_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
              >
                <div
                  className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold"
                  id="kt_landing_menu"
                >
                  {this._renderMenuItems()}
                </div>
              </div>
            </div>
            <div className="flex-equal text-end ms-1">
              <a
                onClick={() => AuthManager.logout()}
                className="btn btn-success"
              >
                Log Out
              </a>
            </div>
          </div>
        </div>

        {this.state.isOpen && (
          <div
            style={{ zIndex: 109 }}
            className="drawer-overlay"
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          />
        )}
      </div>
    )
  }
}
