import React, { Component } from 'react'

import FileItem from '../FileItem'
import YearPicker from '../common/YearPicker'
import VideoModal from '../modals/VideoModal'

import moment from 'moment'

import Backend from '../../../utils/Backend'
export default class Presentations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      years: [],
      isLoading: true,
    }
    this._loadPresentations()
  }

  _getYears(data) {
    let years = []
    data.map((reportGroup) => {
      years.push(reportGroup.year)
    })
    return years
  }

  _loadPresentations() {
    this.setState({ isLoading: true })
    Backend.getAllYearlyReports('investor_presentation', true)
      .then((response) => {
        let years = this._getYears(response)
        this.setState({
          selectedYear: years[0],
          data: response,
          years,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({ isLoading: false })
      })
  }

  _onYearClicked(year) {
    this.setState({ selectedYear: year })
  }

  _renderLoading() {
    if (!this.state.isLoading) return null
    return (
      <div class="container">
        <div class="text-center mb-12">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  _renderNoResults() {
    let { data, isLoading } = this.state
    if (data.length <= 0 && !isLoading) {
      return (
        <div className="text-center mb-12">
          <h6
            className="mb-5"
            id="credit-ratings"
            data-kt-scroll-offset="{default: 100, lg: 150}"
          >
            No presentations available
          </h6>
        </div>
      )
    }
  }

  _renderYears() {
    let { years } = this.state
    if (years.length === 0) {
      return null
    }
    return years.map((year) => {
      return (
        <li className="nav-item">
          <a
            className={
              this.state.selectedYear == year
                ? 'nav-link text-active-primary pb-4 active'
                : 'nav-link text-active-primary pb-4'
            }
            data-bs-toggle="tab"
            onClick={() => this._onYearClicked(year)}
          >
            {year}
          </a>
        </li>
      )
    })
  }

  _renderPresentations() {
    let { data, selectedYear } = this.state
    if (data.length === 0) {
      return null
    }
    let presentations = data.find(
      (group) => group.year === selectedYear
    ).reports
    if (presentations.length === 0) {
      return null
    }
    return presentations.map((item, index) =>
      this._renderPresentation(item, index)
    )
  }

  _renderPresentation(item) {
    return (
      <div className="col-md-6 col-lg-4 col-xl-4">
        <div className="card h-100">
          <div className="card-body d-flex justify-content-center text-center flex-column p-8">
            <a
              href="#"
              className="text-gray-800 text-hover-primary d-flex flex-column"
            >
              <div className="row">
                {item.presentation_url && (
                  <div className="col-md-4">
                    <FileItem
                      url={item.presentation_url}
                      slug={item.slug}
                      className="symbol symbol-60px mb-5"
                    />
                  </div>
                )}
                {item.video_url && (
                  <div
                    className="col-md-4"
                    onClick={() => {
                      this.setState({
                        showVideoModal: true,
                        url: item.video_url,
                      })
                    }}
                  >
                    <div className="symbol symbol-circle bg-primary symbol-60px mb-5 p-3">
                      <span className="svg-icon svg-icon-success svg-icon-3hx">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                )}
                {item.transcript_url && (
                  <div className="col-md-4">
                    <FileItem
                      url={item.transcript_url}
                      slug={item.slug}
                      className="symbol symbol-60px mb-5"
                    />
                  </div>
                )}
              </div>
              <div className="fs-5 fw-bolder mb-2">{item.title}</div>
            </a>
            <div className="fs-7 fw-bold text-gray-400">
              {moment(item.published_at).format('DD MMM, YYYY')}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="py-10 py-lg-20 bg-light">
        <div className="container mb-20">
          <div className="text-center mb-30">
            <h2
              className="mb-5"
              id="presentations"
              data-kt-scroll-offset="{default: 100, lg: 150}"
            >
              PRESENTATIONS
            </h2>
            <hr />
          </div>
          <div className="row">
            <div className="d-flex justify-content-end">
              <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                {this._renderYears()}
              </ul>
            </div>
          </div>
          <div
            className="tab-pane fade active show"
            id="2021-tab"
            role="tabpanel"
          >
            <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
              {this._renderPresentations()}
            </div>
          </div>
          {this._renderNoResults()}
          {this._renderLoading()}
        </div>
        <VideoModal
          title=""
          url={this.state.url}
          show={this.state.showVideoModal}
          onHide={() => this.setState({ showVideoModal: false })}
        />
      </div>
    )
  }
}
