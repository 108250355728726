export default class Data {
  static sections = {
    creditRatings: 'credit_ratings',
    investorResults: 'investor_results',
    investorAnnual: 'investor_annual',
    sustainability: 'sustainability',
    presentation: 'presentation',
    files: 'files',
    other: 'other',
    news: 'news',
    contact: 'contact',
  }

  static OVERVIEW_ITEM = {
    title: 'Overview',
    url: '/',
    icon: (
      <span className="svg-icon svg-icon-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.3"
            d="M11 11H13C13.6 11 14 11.4 14 12V21H10V12C10 11.4 10.4 11 11 11ZM16 3V21H20V3C20 2.4 19.6 2 19 2H17C16.4 2 16 2.4 16 3Z"
            fill="black"
          />
          <path
            d="M21 20H8V16C8 15.4 7.6 15 7 15H5C4.4 15 4 15.4 4 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
            fill="black"
          />
        </svg>
      </span>
    ),
  }

  static REPORTS_ITEM = {
    title: 'Reports',
    url: '/reports',
    icon: (
      <span className="menu-icon">
        <span className="svg-icon svg-icon-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              opacity="0.3"
              d="M11 11H13C13.6 11 14 11.4 14 12V21H10V12C10 11.4 10.4 11 11 11ZM16 3V21H20V3C20 2.4 19.6 2 19 2H17C16.4 2 16 2.4 16 3Z"
              fill="black"
            />
            <path
              d="M21 20H8V16C8 15.4 7.6 15 7 15H5C4.4 15 4 15.4 4 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
              fill="black"
            />
          </svg>
        </span>
      </span>
    ),
  }

  static OUTSTANDING_DEBT_ITEM = {
    title: 'Outstanding Debt',
    url: '/outstanding-debt',
    icon: (
      <span className="menu-icon">
        <span className="svg-icon svg-icon-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path opacity="0.3" d="M3 3V17H7V21H15V9H20V3H3Z" fill="black" />
            <path
              d="M20 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V21C21 21.6 20.6 22 20 22ZM19 4H4V8H19V4ZM6 18H4V20H6V18ZM6 14H4V16H6V14ZM6 10H4V12H6V10ZM10 18H8V20H10V18ZM10 14H8V16H10V14ZM10 10H8V12H10V10ZM14 18H12V20H14V18ZM14 14H12V16H14V14ZM14 10H12V12H14V10ZM19 14H17V20H19V14ZM19 10H17V12H19V10Z"
              fill="black"
            />
          </svg>
        </span>
      </span>
    ),
  }

  static CREDIT_RATINGS_ITEM = {
    title: 'Credit Ratings',
    url: '/credit-ratings',
    icon: (
      <span className="menu-icon">
        <span className="svg-icon svg-icon-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M14 18V16H10V18L9 20H15L14 18Z" fill="black" />
            <path
              opacity="0.3"
              d="M20 4H17V3C17 2.4 16.6 2 16 2H8C7.4 2 7 2.4 7 3V4H4C3.4 4 3 4.4 3 5V9C3 11.2 4.8 13 7 13C8.2 14.2 8.8 14.8 10 16H14C15.2 14.8 15.8 14.2 17 13C19.2 13 21 11.2 21 9V5C21 4.4 20.6 4 20 4ZM5 9V6H7V11C5.9 11 5 10.1 5 9ZM19 9C19 10.1 18.1 11 17 11V6H19V9ZM17 21V22H7V21C7 20.4 7.4 20 8 20H16C16.6 20 17 20.4 17 21ZM10 9C9.4 9 9 8.6 9 8V5C9 4.4 9.4 4 10 4C10.6 4 11 4.4 11 5V8C11 8.6 10.6 9 10 9ZM10 13C9.4 13 9 12.6 9 12V11C9 10.4 9.4 10 10 10C10.6 10 11 10.4 11 11V12C11 12.6 10.6 13 10 13Z"
              fill="black"
            />
          </svg>
        </span>
      </span>
    ),
  }

  static FILE_UPLOADS_ITEM = {
    title: 'File Uploads',
    url: '/files',
    icon: (
      <span className="menu-icon">
        <span className="svg-icon svg-icon-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              opacity="0.3"
              d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
              fill="black"
            />
            <path
              d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z"
              fill="black"
            />
            <path
              opacity="0.3"
              d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z"
              fill="black"
            />
          </svg>
        </span>
      </span>
    ),
  }

  static PRESENTATIONS_ITEM = {
    title: 'Presentations',
    url: '/presentations',
    icon: (
      <span className="menu-icon">
        <span className="svg-icon svg-icon-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              opacity="0.3"
              d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
              fill="black"
            />
            <path
              d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
              fill="black"
            />
          </svg>
        </span>
      </span>
    ),
  }

  static USERS_ITEM = {
    title: 'Manage Users',
    url: '/users',
    icon: (
      <span className="menu-icon">
        <span className="svg-icon svg-icon-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
              fill="black"
            />
            <rect
              opacity="0.3"
              x={14}
              y={4}
              width={4}
              height={4}
              rx={2}
              fill="black"
            />
            <path
              d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
              fill="black"
            />
            <rect
              opacity="0.3"
              x={6}
              y={5}
              width={6}
              height={6}
              rx={3}
              fill="black"
            />
          </svg>
        </span>
      </span>
    ),
  }

  static EMAILS_ITEM = {
    title: 'Emails',
    url: '/emails',
    icon: (
      <span className="menu-icon">
        <span className="svg-icon svg-icon-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              opacity="0.3"
              d="M21 18H3C2.4 18 2 17.6 2 17V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V17C22 17.6 21.6 18 21 18Z"
              fill="black"
            />
            <path
              d="M11.4 13.5C11.8 13.8 12.3 13.8 12.6 13.5L21.6 6.30005C21.4 6.10005 21.2 6 20.9 6H2.99998C2.69998 6 2.49999 6.10005 2.29999 6.30005L11.4 13.5Z"
              fill="black"
            />
          </svg>
        </span>
      </span>
    ),
  }
}
