import React from 'react'

import Menu from './components/Menu'
import Footer from './components/Footer'
import Results from './components/sections/Results'
import AnnualResults from './components/sections/AnnualResults'
import ScrollTop from './components/ScrollTop'
import CreditRating from './components/sections/CreditRating'
import Files from './components/sections/Files'
import News from './components/sections/News'
import Sustainability from './components/sections/Sustainability'
import Presentations from './components/sections/Presentations'
import Contact from './components/sections/Contact'
import Other from './components/sections/Other'
import CustomSvg from './components/common/CustomSvg'

import Backend from '../utils/Backend'
import Data from '../utils/Data'
import DateTime from '../utils/DateTime'

const timezone = 'America/New_York'
export default class Home extends React.Component {
  constructor() {
    super()
    this.state = {
      sectionsSettings: null,
      isLoading: true,
    }
    this._loadSectionsSettings()
    this._getUpcomingResults()
  }

  _loadSectionsSettings() {
    this.setState({
      isLoading: true,
    })
    Backend.getSectionsSettings()
      .then((response) => {
        let creditRatingsSettings = response.find(
          (section) => section.key === Data.sections.creditRatings
        )
        let resultsSettings = response.find(
          (section) => section.key === Data.sections.investorResults
        )

        let annualSettings = response.find(
          (section) => section.key === Data.sections.investorAnnual
        )
        let sustainabilitySettings = response.find(
          (section) => section.key === Data.sections.sustainability
        )
        let presentationSettings = response.find(
          (section) => section.key === Data.sections.presentation
        )
        let filesSettings = response.find(
          (section) => section.key === Data.sections.files
        )
        let otherSettings = response.find(
          (section) => section.key === Data.sections.other
        )
        let newsSettings = response.find(
          (section) => section.key === Data.sections.news
        )
        let contactsSettings = response.find(
          (section) => section.key === Data.sections.contact
        )
        this.setState({
          isLoading: false,
          showCreditRatings: creditRatingsSettings.visible,
          showResults: resultsSettings.visible,
          showAnnual: annualSettings.visible,
          showSustainability: sustainabilitySettings.visible,
          showPresentations: presentationSettings.visible,
          showFiles: filesSettings.visible,
          showOther: otherSettings.visible,
          showNews: newsSettings.visible,
          showContacts: contactsSettings.visible,
        })
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        })
      })
  }

  _getUpcomingResults() {
    this.setState({
      isLoading: true,
    })
    Backend.getUpcomingResults()
      .then((response) => {
        if (response.length <= 0) {
          this.setState({
            isLoading: false,
            upcomingResults: null,
          })
        }
        this.setState({
          upcomingResults: response[0],
          isLoading: false,
        })
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        })
      })
  }

  _renderFile() {
    let { upcomingResults } = this.state
    if (!upcomingResults.file_url) {
      return null
    }
    let url = upcomingResults.file_url
    let fileType = url.split('.').pop()
    if (!url.includes('cloudfront')) {
      fileType = 'link'
    }
    return (
      <a
        href={url}
        target="_blank"
        onClick={() => this._fileDownloaded()}
        className={'text-dark fw-bolder text-hover-primary mb-1 fs-6'}
      >
        <div className={this.props.className || 'symbol symbol-50px me-5'}>
          <CustomSvg text={fileType} />
        </div>
      </a>
    )
  }

  _renderHeader() {
    let { upcomingResults } = this.state

    return (
      <div className="py-10 py-lg-20 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h2>Welcome Back</h2>
              <hr className="align-left" />
              <p className="text-muted fw-bold fs-5 pe-20">
                By accessing the below documents, you are doing so on the basis
                that they are for your own use; consistent with the terms agreed
                to on accessing this secure site; and not for distribution to
                any other party.
              </p>
            </div>
            {this.state.upcomingResults && (
              <div className="col-md-7">
                <div
                  className="card bgi-no-repeat card-xl-stretch mb-xl-8 border border-gray-300 border-dashed rounded py-3 px-3 mb-3"
                  style={{
                    backgroundPosition: 'right top',
                    backgroundSize: '15% auto',
                    backgroundImage:
                      'url(../assets/media/icons/avolon-icon.png)',
                  }}
                >
                  <div className="card-body">
                    <a
                      href="#"
                      className="card-title fw-bolder text-muted fs-4"
                    >
                      {upcomingResults?.title}
                    </a>
                    <div className="fw-bolder text-primary my-6">
                      {DateTime.formatDate(upcomingResults.due_date, timezone)}{' '}
                    </div>
                    <p
                      className="text-dark-75 fw-bold fs-5 m-0"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {upcomingResults.description}
                    </p>
                    {upcomingResults.file_url && (
                      <p className="text-dark-75 fw-bold fs-5 m-0">
                        Event Detail: {this._renderFile()}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    let {
      isLoading,
      showResults,
      showAnnual,
      showNews,
      showOther,
      showSustainability,
      showPresentations,
      showFiles,
      showCreditRatings,
      showContacts,
    } = this.state
    if (isLoading) {
      return null
    }
    console.log('showCreditRatings', showCreditRatings)
    return (
      <div className="d-flex flex-column flex-root">
        <div className="mb-0" id="home">
          <div
            className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
            style={{
              backgroundImage:
                'url(../../assets/media/svg/illustrations/landing.svg)',
            }}
          >
            <Menu />
            {this._renderHeader()}
            {showCreditRatings && <CreditRating />}
            {showResults && <Results />}
            {showAnnual && <AnnualResults />}
            {showSustainability && <Sustainability />}
            {showPresentations && <Presentations />}
            {/* <OrixAnnouncements /> */}
            {showFiles && <Files />}
            {showOther && <Other />}
            {/* <OutstandingDebt /> */}
            {showNews && <News />}
            {showContacts && <Contact />}
            {<Footer />}
            <ScrollTop />
          </div>
        </div>
      </div>
    )
  }
}
