import React, { Component } from 'react'

import Modal from 'react-bootstrap/Modal'

export default class VideoModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      url: props.url,
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  isCloudFrontUrl(url) {
    if (!url) {
      return false
    }
    if (url.includes('cloudfront')) {
      return true
    }
    return false
  }

  render() {
    const { url, show, isLoading } = this.state
    const isCloudFrontUrl = this.isCloudFrontUrl(url)
    return (
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {!isCloudFrontUrl && (
              <iframe
                width="100%"
                height={400}
                src={url}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            )}
            {isCloudFrontUrl && (
              <video
                width="100%"
                controls
                controlsList="nodownload"
                style={{ backgroundColor: 'black', maxHeight: '70vh' }}
              >
                <source src={url} />
                Your browser does not support the video tag.
              </video>
            )}
          </Modal.Body>
        </div>
      </Modal>
    )
  }
}
