import FetchHelper from './FetchHelper'

import moment from 'moment'
import DateTime from './DateTime'
export default class Backend {
  static createLoginHash() {
    return FetchHelper.post(`${window.Api.Hashes}`, { type: 'login' })
  }
  static getMenu() {
    return FetchHelper.get(window.Api.Menu + '?live=true')
  }
  static getFooter() {
    return FetchHelper.get(window.Api.Footer)
  }
  static getSectionsSettings() {
    return FetchHelper.get(`${window.Api.Sections}`)
  }
  static updateDownloadCount(slug) {
    return FetchHelper.get(`${window.Api.Reports}/${slug}/download`)
  }

  static getNews() {
    return FetchHelper.get(
      window.Api.Reports + '?category=News&year=all&live=true'
    )
  }

  static getLatestReports(category) {
    let endpoint =
      window.Api.Reports + '?category=' + category + '&recent=true&live=true'
    return FetchHelper.get(endpoint, false)
  }

  static getAllYearlyReports(category, group_by_year) {
    let endpoint =
      window.Api.Reports + '?category=' + category + '&all_years=True'
    if (group_by_year) {
      endpoint += `&group_by_year=${DateTime.getArchiveYear()}`
    }
    return FetchHelper.get(endpoint + '&live=true', false)
  }

  static getReports(category, year) {
    if (year === 'Archive') {
      return FetchHelper.get(
        window.Api.Reports +
          '?category=' +
          category +
          `&archive_year=${DateTime.getArchiveYear()}`,
        false
      )
    }
    let endpoint = window.Api.Reports + '?category=' + category
    if (year) {
      endpoint += `&year=${year}`
    }
    return FetchHelper.get(endpoint + '&live=true', false)
  }

  static getUpcomingResults() {
    return FetchHelper.get(window.Api.UpcomingResults + '?future=True', false)
  }

  static getFinancialStats() {
    return FetchHelper.get(window.Api.FinancialStats, false)
  }

  static getCreditRatings() {
    return FetchHelper.get(window.Api.CreditRatings, false)
  }

  static getFiles() {
    return FetchHelper.get(`${window.Api.Files}`)
  }

  static uploadImage(image, type = 'favicon') {
    let data = new FormData()

    data.append('file', image, image.name)
    data.append('type', type)

    return FetchHelper.post(window.Api.Images, data, true)
  }

  static uploadFile(localFile) {
    let file = null

    return FetchHelper.getPresignedUploadUrl(localFile)
      .then((response) => {
        file = response.file
        return FetchHelper.put(
          response.pre_signed_url,
          localFile,
          false,
          false,
          false
        )
      })
      .then(() => Backend.markFileUploaded(file, file.token))
  }

  static getPresignedUploadUrl(file, meta) {
    let data = {
      title: file.name,
    }

    if (meta?.duration) {
      data.duration = Math.round(meta.duration)
    }

    if (meta?.size) {
      data.size = meta.size
    }

    return FetchHelper.post(window.Api.FilesV2, data)
  }

  static markFileUploaded(id, token) {
    return FetchHelper.patch(`${window.Api.FilesV2}/${id}`, { token })
  }
}
