import moment from 'moment'

export default class Config {
  // Set as true to show this year and false for previous year
  static getStartYear(showCurrentYear = false) {
    if (showCurrentYear) {
      return moment().format('YYYY')
    }
    return moment().format('YYYY') - 1
  }
}
