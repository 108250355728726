import moment from 'moment'
import React, { Component } from 'react'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'

import runtimeEnv from '@mars/heroku-js-runtime-env'

const env = runtimeEnv()
export default class News extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      data: [],
    }
    this._loadNews()
  }

  _loadNews() {
    this.setState({ isLoading: true })
    Backend.getNews()
      .then((response) => {
        this.setState({ data: response, isLoading: false })
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  _renderNoResults() {
    let { data, isLoading } = this.state
    if (data.length <= 0 && !isLoading) {
      return (
        <div className="text-center mb-12">
          <h6
            className="mb-5"
            id="credit-ratings"
            data-kt-scroll-offset="{default: 100, lg: 150}"
          >
            No reports available
          </h6>
        </div>
      )
    }
  }

  _renderLoading() {
    if (!this.state.isLoading) return null
    return (
      <div class="container">
        <div class="text-center mb-12">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  _renderRows() {
    let { data } = this.state
    if (data.length <= 0) {
      return null
    }
    return data.map((item, index) => this._renderRow(item, index))
  }

  _renderRow(item, index) {
    if (index > 2) return null
    return (
      <div className="col-md-4 m-b-25">
        <p className="type--uppercase m-b-2 type--bold color--primary">
          {moment(item.created_at).format('Do MMM YYYY')}
        </p>
        <h5 className="type--uppercase m-b-10">{item.title}</h5>
        <p className=" m-b-5">{item.subtitle}</p>
        <a
          href="javascript:void(0)"
          onClick={() => {
            window.location.href =
              env.REACT_APP_WEBSITE_DOMAIN +
              '/newsroom-and-thoughts/' +
              item.slug
          }}
          className="type--uppercase nounderline anim-link-hover-1"
          data-ol-has-click-handler
        >
          read more
        </a>
      </div>
    )
  }

  render() {
    return (
      <div className=" py-10 py-lg-20 bg-light" id="latest-news">
        <div className="container">
          <div className="text-center mb-12">
            <h2
              className="mb-5"
              id="credit-ratings"
              data-kt-scroll-offset="{default: 100, lg: 150}"
            >
              LATEST FROM THE NEWSROOM
            </h2>
            <hr />
          </div>
          <div className="row justify-content-between mt-10">
            {this._renderRows()}
          </div>
          {this._renderLoading()}
          {this._renderNoResults()}
        </div>
      </div>
    )
  }
}
