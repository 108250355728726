import React from 'react'

import { Route, Switch, Redirect } from 'react-router-dom'

import Home from './Home'

export default class App extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route path="/" component={Home} />
        </Switch>
      </>
    )
  }
}
