import moment from 'moment-timezone'
export default class DateTime {
  static getArchiveYear() {
    return new Date().getFullYear() - 3
  }

  static formatDate(date, timezone, format = 'Do MMM YYYY') {
    return moment(date).tz(timezone).format(format)
  }

  static formatTime(time, timezone, format = 'hh:mm A') {
    return moment(time).tz(timezone).format(format)
  }

  static getTimezoneAbbreviation(timezone) {
    return moment.tz(timezone).zoneAbbr()
  }
}
