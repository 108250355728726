import React, { Component } from 'react'

import VideoModal from '../modals/VideoModal'

import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'

import moment from 'moment'
export default class Sustainability extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      data: [],
      years: [],
    }
    this._loadSustainabilityHeader()
  }

  _fileDownloaded(slug) {
    Backend.updateDownloadCount(slug)
      .then(() => {
        // do nothing
      })
      .catch((err) => {
        Notify.error(err.message)
      })
  }

  _getYears(data) {
    let years = []
    data.map((reportGroup) => {
      years.push(reportGroup.year)
    })
    return years
  }

  _loadSustainabilityHeader() {
    Backend.getLatestReports('investor_sustainability')
      .then((response) => {
        if (response.length > 0) {
          this.setState({ headerReport: null })
        }
        this.setState({ headerReport: response[0], isLoading: false }, () => {
          this._loadSustainabilityReports()
        })
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  _loadSustainabilityReports() {
    this.setState({ isLoading: true })
    Backend.getAllYearlyReports('investor_sustainability')
      .then((response) => {
        let years = this._getYears(response)
        this.setState({
          selectedYear: years[0],
          data: response,
          years,
          isLoading: false,
        })
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  _renderNoResults() {
    let { data, selectedYear, isLoading } = this.state
    if (data.length === 0 || !isLoading) {
      return null
    }
    let reports = data.find((group) => group.year === selectedYear).reports
    if (reports.length <= 0 && !isLoading) {
      return (
        <div className="text-center mb-12">
          <h6
            className="mb-5"
            id="credit-ratings"
            data-kt-scroll-offset="{default: 100, lg: 150}"
          >
            No reports available
          </h6>
        </div>
      )
    }
  }

  _renderLoading() {
    if (!this.state.isLoading) return null
    return (
      <div class="container">
        <div class="text-center mb-12">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  _onYearClicked(year) {
    this.setState({ selectedYear: year })
  }

  _renderYears() {
    let { years } = this.state
    if (years.length === 0) {
      return null
    }
    return years.map((year) => {
      return (
        <li className="nav-item">
          <a
            className={
              this.state.selectedYear == year
                ? 'nav-link text-active-primary pb-4 active'
                : 'nav-link text-active-primary pb-4'
            }
            data-bs-toggle="tab"
            onClick={() => this._onYearClicked(year)}
          >
            {year}
          </a>
        </li>
      )
    })
  }

  _renderReports() {
    let { data, selectedYear } = this.state
    if (data.length === 0) {
      return null
    }
    let reports = data.find((group) => group.year === selectedYear).reports
    if (reports.length === 0) {
      return null
    }
    return reports.map((item, index) => this._renderReport(item, index))
  }

  _renderTopReport() {
    let item = this.state.headerReport
    if (!item) {
      return null
    }
    return (
      <>
        <div className="col-md-6">
          <div
            className="item-image"
            style={{ backgroundImage: 'url(' + item.image.url + ')' }}
          />
        </div>
        <div className="col-md-6 ps-20">
          <div
            className="d-flex flex-column flex-row-fluid"
            style={{
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <div className="d-flex align-items-center flex-wrap mb-1">
              <a
                href="#"
                className="text-gray-800 text-hover-primary fw-bolder me-2 fs-1"
              >
                {item.title}
              </a>
            </div>
            <span className="text-gray-800 fs-4 fw-normal pt-1 mt-10">
              {item.subtitle}
            </span>
            <a
              href={item.file_url}
              target="_blank"
              onClick={() => this._fileDownloaded(item.slug)}
              className="btn btn-rounded btn-primary mt-5 w-200px m d-inline-block"
            >
              DOWNLOAD REPORT
            </a>
            {item.video_url && (
              <a
                onClick={() => {
                  this.setState({
                    showVideoModal: true,
                    url: item.video_url,
                  })
                }}
                className="btn btn-rounded btn-secondary mt-5 w-200px d-inline-block"
                data-bs-toggle="modal"
                data-bs-target="#view-video"
              >
                WATCH VIDEO
              </a>
            )}
          </div>
        </div>
      </>
    )
  }

  _renderHeaderReport() {
    return (
      <>
        {this._renderTopReport()}
        <div
          className={
            this.state.headerReport ? 'col-md-12 pt-20' : 'col-md-12 pt-0'
          }
        >
          <div className="card mb-5 mb-xl-8 bg-light">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-1 mb-1">
                  Sustainability Archive
                </span>
              </h3>
              <div className="card-toolbar">
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                  {this._renderYears()}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  _renderReport(item, index) {
    let fileType = item.file_url.split('.').pop()
    return (
      <div className="col-md-6 col-lg-4 col-xl-4">
        <div className="card h-100">
          <div className="card-body d-flex justify-content-center text-center flex-column p-8">
            <a
              href="#"
              className="text-gray-800 text-hover-primary d-flex flex-column"
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="symbol symbol-60px mb-5">
                    <img
                      src={'assets/media/svg/files/' + fileType + '.svg'}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="fs-5 fw-bolder mb-2">{item.title}</div>
            </a>
            <div className="fs-7 fw-bold text-gray-400">
              {moment(item.published_at).format('Do MMM YYYY')}
            </div>
            <a
              href={item.file_url}
              target="_blank"
              onClick={() => this._fileDownloaded(item.slug)}
              className="btn btn-primary btn-rounded mt-5"
            >
              DOWNLOAD REPORT
            </a>
            {item.video_url && (
              <a
                onClick={() => {
                  this.setState({
                    showVideoModal: true,
                    url: item.video_url,
                  })
                }}
                className="btn btn-secondary btn-rounded mt-3"
              >
                WATCH VIDEO
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className="py-10 py-lg-20 bg-white sustainability-table"
        id="sustainability"
      >
        <div className="container">
          <div className="text-center mb-12">
            <h2
              className="mb-5"
              id="credit-ratings"
              data-kt-scroll-offset="{default: 100, lg: 150}"
            >
              SUSTAINABILITY
            </h2>
            <hr />
          </div>
          <div className="row rounded p-20 bg-light">
            {this._renderHeaderReport()}
            <div
              className="tab-pane fade active show"
              id="2022-tab"
              role="tabpanel"
            >
              <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                {this._renderReports()}
              </div>
            </div>
            {this._renderLoading()}
            {this._renderNoResults()}
          </div>
        </div>
        <VideoModal
          title=""
          url={this.state.url}
          show={this.state.showVideoModal}
          onHide={() => this.setState({ showVideoModal: false })}
        />
      </div>
    )
  }
}
