import React from 'react'

import logo from '../../assets/media/logos/avolon-logo.png'

export default class NotFound extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <div className="notFound-container">
        <div className="logo-section">
          <img src={logo} alt="Logo" />
        </div>
        <h2>Oops...</h2>
        <h4>Looks like you are lost.</h4>
        <button
          onClick={() => this.props.history.push('/')}
          className="btn btn-primary home-btn"
        >
          Back to Home
        </button>
      </div>
    )
  }
}
