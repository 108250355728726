import React from 'react'

import Modal from 'react-bootstrap/Modal'

export default class TermsModal extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps)
  }

  render() {
    let { show, isLoading } = this.props

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Legal Disclaimer</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            The Materials (as defined below) have been prepared by Avolon
            Holdings Limited (“Avolon” or the “Company”) and are confidential
            and proprietary and may contain material nonpublic information. The
            Materials are being furnished to a limited number of sophisticated
            investors and/or certain of their Representatives (as defined below)
            for the sole purpose of providing information about, and documents
            with respect to, Park Aerospace Holdings Limited (“Park”) and/or its
            affiliates (together, the “Investment”) in a convenient format in
            connection with your role as an investor or the evaluation of a
            possible investment and may not be used for any other purpose. The
            “Materials” means (a) the materials provided in this workspace
            (whether such materials are contained in this workspace as of the
            date hereof or at any date hereafter), (b) all materials that use,
            contain reflect or are derived from or incorporate the materials
            referred to in clause (a), and (c) all other materials that relate
            to nonpublic information regarding Avolon and/or its subsidiaries or
            affiliates and are provided in connection with the potential
            Investment, in each case whether provided in this workspace or
            otherwise (for example, by e-mail or hard copy).
            <br />
            <br />
            By clicking “agree” and accessing this workspace you acknowledge and
            agree:
            <br />
          </p>
          <ol>
            <li style={{ marginBottom: 10 }}>
              that you are one of:{' '}
              <ol type="a">
                <li style={{ marginBottom: 5 }}>
                  An existing holder of one or more series of notes issued by
                  Park or one of its affiliates; An institution acting as
                  trustee for one or more series of notes issued by Park or one
                  of its affiliates;
                </li>
                <li>
                  A bona fide prospective purchaser of one or more series of
                  notes issued by Park or one of its affiliates; or
                </li>
                <li>
                  A market maker affiliated with any initial purchaser of one or
                  more series of notes issued by Park or one of its affiliates.
                </li>
              </ol>
            </li>
            <li style={{ marginBottom: 10 }}>
              that by gaining access to the Materials, you will be in possession
              of confidential information, the improper use or disclosure of
              which could have a material adverse effect on Avolon and/or its
              affiliates;
            </li>
            <li style={{ marginBottom: 10 }}>
              to keep all Materials strictly confidential and to use all
              reasonable means to protect the Materials against disclosure to,
              or use by, any unauthorized person (which protections will entail
              the same degree of reasonable care as you would use to preserve
              the confidentiality of your own confidential information, unless
              such disclosure is required by applicable law, governmental rule
              or regulation, court order, administrative or arbitral proceeding
              or by any regulatory or judicial authority having jurisdiction
              over you (collectively, “Applicable Law”));
            </li>
            <li style={{ marginBottom: 10 }}>
              to review and use the Materials solely for the purpose of
              obtaining information about the potential Investment and not to
              reproduce the Materials or to distribute them to others, at any
              time, in whole or in part, without the prior written consent of
              Avolon.
            </li>
            <li style={{ marginBottom: 10 }}>
              to be included on Avolon’s mailing list to receive news releases
              and business updates from the Company. Users will be able to
              unsubscribe at any future time as each email contains an
              unsubscribe link at the bottom of each email.
            </li>
          </ol>

          <p />
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            disabled={isLoading}
            onClick={() => this.props.onHide()}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

TermsModal.defaultProps = {
  loading: false,
}
