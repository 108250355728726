import React, { Component } from 'react'
import moment from 'moment'
import Backend from '../../../utils/Backend'
import Notify from '../../../utils/Notify'
import TextFormat from '../../../utils/TextFormat'
export default class CreditRating extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      data: [],
    }
    this._loadCreditRatings()
  }

  _loadCreditRatings() {
    this.setState({ isLoading: true })
    Backend.getCreditRatings()
      .then((response) => {
        this.setState({ data: response, isLoading: false })
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  _renderRow(item) {
    return (
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div
              className="c-logo me-5"
              style={{ backgroundImage: 'url(' + item.logo_url + ')' }}
            />
            <div className="d-flex justify-content-start flex-column">
              <a href="#" className="text-dark fw-bolder mb-1 fs-6">
                {item.name}
              </a>
            </div>
          </div>
        </td>
        <td>
          <a href="#" className="text-dark fw-bolder d-block mb-1 fs-6">
            {item.rating}
          </a>
        </td>
        <td>
          <a
            href="#"
            className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
          >
            {moment(item.issued_at).format('Do MMM YYYY')}
          </a>
        </td>
        {item?.outlook && (
          <td>
            <span
              className={
                item?.outlook === 'stable'
                  ? 'badge badge-secondary fs-7 fw-bold w-150px'
                  : item.outlook === 'positive'
                  ? 'badge badge-light-primary fs-7 fw-bold w-150px'
                  : 'badge badge-light-danger fs-7 fw-bold w-150px'
              }
            >
              {TextFormat.capitalizeFirst(item?.outlook)}
            </span>
          </td>
        )}
      </tr>
    )
  }

  _renderLoading() {
    if (!this.state.isLoading) return null
    return (
      <div class="container">
        <div class="text-center mb-12">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  _renderRows() {
    let { data } = this.state
    if (data.length <= 0) {
      return null
    }
    return data.map((item) => this._renderRow(item))
  }

  _renderNoResults() {
    let { data, isLoading } = this.state
    if (data.length <= 0 && !isLoading) {
      return (
        <div className="text-center mb-12">
          <h6
            className="mb-5"
            id="credit-ratings"
            data-kt-scroll-offset="{default: 100, lg: 150}"
          >
            No credit ratings available
          </h6>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="py-10 py-lg-20 bg-white credit-ratings-table">
        <div className="container">
          <div className="text-center mb-12">
            <h2
              className="mb-5"
              id="credit-ratings"
              data-kt-scroll-offset="{default: 100, lg: 150}"
            >
              CREDIT RATINGS
            </h2>
            <hr />
          </div>

          <div className="table-responsive">
            <table className="table align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bolder text-muted bg-light">
                  <th className="ps-4 min-w-325px rounded-start">Agency</th>
                  <th className="min-w-125px">Rating</th>
                  <th className="min-w-125px">Issued</th>
                  <th className="min-w-200px">Outlook</th>
                </tr>
              </thead>
              <tbody>{this._renderRows()}</tbody>
            </table>
            {this._renderNoResults()}
            {this._renderLoading()}
          </div>
        </div>
      </div>
    )
  }
}
