import React, { Component } from 'react'
import Backend from '../../utils/Backend'

import runtimeEnv from '@mars/heroku-js-runtime-env'

const env = runtimeEnv()

export default class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this._loadFooter()
  }

  _loadFooter() {
    Backend.getFooter()
      .then((response) => {
        this.setState({ footerData: response })
      })
      .catch((err) => {
        this.setState({
          footer: null,
        })
      })
  }

  _renderFooterColumns() {
    let { footerData } = this.state
    if (!footerData) {
      return null
    }
    return footerData.map((column, index) => {
      return this._renderFooterColumn(column, index)
    })
  }

  _renderPages(column) {
    return column.pages.map((page, index) => {
      return this._renderPage(column, page, index)
    })
  }

  _renderPage(column, page, index) {
    return (
      <a
        href={env.REACT_APP_WEBSITE_DOMAIN + '/' + page.slug}
        className="text-white opacity-50 text-hover-primary fs-5"
      >
        {column.title.toLowerCase() === page.title.toLowerCase()
          ? 'Overview'
          : page.title}
      </a>
    )
  }

  _renderFooterColumn(column, index) {
    return (
      <div className="col-lg-2 pe-lg-0 mb-10 mb-lg-0">
        <div className="d-flex justify-content-center">
          <div className="d-flex fw-bold flex-column me-10">
            <h6 className="fw-bolder text-white mb-2">{column.title}</h6>
            {this._renderPages(column)}
            {column.title.toLowerCase() === 'life at avolon' && (
              <a
                href={env.REACT_APP_WEBSITE_DOMAIN + '/insead'}
                className="text-white opacity-50 text-hover-primary fs-5"
              >
                Avolon &amp; INSEAD
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="mb-0">
        <div className="bg-black pt-20">
          <div className="container">
            <div className="row py-10 py-lg-0">
              {this._renderFooterColumns()}
              <div className="col-lg-2 pe-lg-0 mb-10 mb-lg-0">
                <div className="d-flex justify-content-center">
                  <div className="d-flex fw-bold flex-column me-20">
                    <h6 className="fw-bolder text-white mb-2">
                      <a
                        href={env.REACT_APP_WEBSITE_DOMAIN + '/care'}
                        className="fw-bolder text-white mb-2"
                      >
                        CARE
                      </a>
                    </h6>

                    <h6 className="fw-bolder text-white mb-2">
                      <a
                        href={
                          env.REACT_APP_WEBSITE_DOMAIN +
                          '/newsroom-and-thoughts'
                        }
                        className="fw-bolder text-white mb-2"
                      >
                        NEWS &amp; THOUGHTS
                      </a>
                    </h6>
                    <a
                      href={env.REACT_APP_WEBSITE_DOMAIN + '/thoughts'}
                      className="text-white opacity-50 text-hover-primary fs-5 mb-2"
                    >
                      Our Thoughts
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 pe-lg-0 mb-10 mb-lg-0">
                <div className="d-flex justify-content-center">
                  <div className="d-flex fw-bold flex-column me-20">
                    <h6 className="fw-bolder text-white mb-6">
                      <a
                        href={env.REACT_APP_WEBSITE_DOMAIN + '/contact'}
                        className="fw-bolder text-white mb-6"
                      >
                        CONTACT
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row countrynav">
              <div className="col-10 text-left text-left-xs">
                <ul className="list-inline list--hover">
                  <li>
                    <a>Dublin</a>
                  </li>
                  <li>
                    <a>Florida</a>
                  </li>
                  <li>
                    <a>Dubai</a>
                  </li>
                  <li>
                    <a>Singapore</a>
                  </li>
                  <li>
                    <a>Hong Kong</a>
                  </li>
                </ul>
              </div>
              <div className="col-2 text-right">
                <a
                  href="#start"
                  data-scroll-class="100vh:active"
                  className="active"
                >
                  <i className="stack-interface stack-up-open-big" />
                </a>
              </div>
            </div>
          </div>

          <div className="landing-dark-separator" />

          <div className="container">
            <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
              <div className="d-flex align-items-center order-2 order-md-1">
                <span className="mx-5 fs-6 fw-bold text-gray-600 pt-1" href="#">
                  © Avolon {new Date().getFullYear()}
                </span>
              </div>
              <ul className="menu menu-white menu-hover-primary fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                <li className="menu-item">
                  <a
                    href={env.REACT_APP_WEBSITE_DOMAIN + '/disclaimer'}
                    className="menu-link px-2"
                  >
                    Disclaimer
                  </a>
                </li>
                <li className="menu-item mx-5">
                  <a
                    href={env.REACT_APP_WEBSITE_DOMAIN + '/privacy'}
                    className="menu-link px-2"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://dm1es2gjsclbk.cloudfront.net/files/08-04-2021_11:49:24.pdf"
                    target="_blank"
                    className="menu-link px-2"
                  >
                    Code of Conduct
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://dm1es2gjsclbk.cloudfront.net/files/08-04-2021_16:12:12.pdf"
                    target="_blank"
                    className="menu-link px-2"
                  >
                    Partner Code of Conduct
                  </a>
                </li>
              </ul>
              <ul className="menu menu-white menu-hover-primary fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                <li className="menu-item">
                  <a
                    href="https://mosaic.ie/"
                    target="_blank"
                    className="menu-link px-2"
                  >
                    Designed by mosaic
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://www.linkedin.com/company/avolon"
                    target="_blank"
                  >
                    <img
                      src="assets/media/svg/brand-logos/linkedin.svg"
                      className="h-20px me-2"
                      alt=""
                    />
                  </a>
                </li>
                <li className="menu-item">
                  <a href="https://twitter.com/avolon_aero" target="_blank">
                    <img
                      src="assets/media/svg/brand-logos/twitter.svg"
                      className="h-20px me-2"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
