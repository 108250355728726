import React from "react";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import LocationInput from "./components/common/LocationInput";
import TermsModal from "./components/modals/TermsModal";

import AuthManager from "../utils/AuthManager";
import Email from "../utils/Email";
import Notify from "../utils/Notify";

import ReCAPTCHA from "react-google-recaptcha";

const env = runtimeEnv();

const MODE_SIGNUP = "signup";
const MODE_SIGNIN = "signin";
const MODE_FORGOT_PASSWORD = "forgot_password";
const MODE_RESET_PASSWORD = "reset_password";
export default class Login extends React.Component {
  constructor(props) {
    super(props);

    var mode =
      window.location.href.indexOf("m=r") > -1
        ? MODE_FORGOT_PASSWORD
        : MODE_SIGNIN;

    this.state = {
      email: "",
      password: "",
      isLoading: false,
      showPassword: false,
      showConfirmPassword: false,
      signUpStep: 1,
      mode: mode,
      recaptcha: null,
    };
  }

  componentDidMount() {
    try {
      document.body.className += " page-login";
    } catch (err) {
      console.error(err);
    }
  }

  _isFormValid() {
    let { email, password, confirm_password, mode, code } = this.state;

    if (!Email.isValid(email)) {
      this.setState({ error: "Please enter a valid email address" });
      return false;
    }

    if (mode === MODE_RESET_PASSWORD) {
      if (!code) {
        this.setState({ error: "Please enter a valid code" });
        return false;
      }
      if (password.length < 6) {
        this.setState({ error: "Password must be at least 6 characters" });
        return false;
      }
      if (password !== confirm_password) {
        this.setState({ error: "Passwords do not match" });
        return false;
      }
    }
    return true;
  }

  _updateMode(mode) {
    this.setState({
      mode,
      password: "",
      confirm_password: "",
      showPassword: false,
      showConfirmPassword: false,
    });
  }

  _handleLogInClicked(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password } = this.state;

    this.setState({ isLoading: true });
    AuthManager.login(email, password, false)
      .then(() => {
        this.setState({ isLoading: false });
        window.location = "/";
      })
      .catch((error) => {
        if (error.force_password_change) {
          this._handleRequestResetPassword(e);
        }
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _handleRequestResetPassword(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email } = this.state;

    this.setState({ isLoading: true });
    AuthManager.requestResetPassword(email)
      .then(() => {
        this._updateMode(MODE_RESET_PASSWORD);
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _handleResetPassword(e) {
    e.preventDefault();
    if (!this._isFormValid()) {
      return;
    }
    let { email, password, code } = this.state;

    this.setState({ isLoading: true });
    AuthManager.resetPassword(email, password, code)
      .then(() => {
        this._updateMode(MODE_SIGNIN);
        this.setState({
          isLoading: false,
          error: null,
        });
        Notify.success("Your password has been reset");
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _isSignUpFormValid() {
    let {
      first_name,
      last_name,
      email,
      confirm_password,
      password,
      agree,
      job_title,
      company,
      location,
      recaptcha,
    } = this.state;
    let error = null;
    if (first_name == null || first_name.length === 0) {
      error = "Please enter a valid first name";
    } else if (last_name == null || last_name.length === 0) {
      error = "Please enter a valid last name";
    } else if (location == null || !location) {
      error = "Please enter a valid location";
    } else if (job_title == null || job_title.length === 0) {
      error = "Please enter a valid job title";
    } else if (company == null || company.length === 0) {
      error = "Please enter a valid company";
    } else if (email == null || email.length === 0) {
      error = "Please enter a valid email";
    } else if (confirm_password == null || confirm_password !== password) {
      error = "Passwords do not match";
    } else if (password == null || password.length === 0) {
      error = "Please enter a valid password";
    } else if (!agree) {
      error = "Please accept Terms & Conditions and Privacy Policy.";
    } else if (!recaptcha) {
      error = "Please complete the reCAPTCHA challenge.";
    }
    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _signUpPressed(e) {
    e.preventDefault();

    if (!this._isSignUpFormValid()) {
      return;
    }

    const {
      first_name,
      last_name,
      email,
      password,
      agree,
      company,
      job_title,
      location,
      recaptcha,
    } = this.state;

    let data = {
      first_name,
      last_name,
      email,
      password,
      company,
      location,
      job_title,
      mail_subscribed: agree,
      role: "investor",
      recaptcha,
    };

    this.setState({ isLoading: true });

    AuthManager.register(data)
      .then(() => {
        window.location = "/";
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        Notify.error(error.message);
      });
  }

  _renderError() {
    let { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    );
  }

  _renderSignIn() {
    let { email, password, isLoading } = this.state;
    return (
      <form
        className="form w-100"
        noValidate="novalidate"
        id="kt_sign_in_form"
        action="#"
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Sign In</h1>
          <div className="text-gray-400 fw-bold fs-4">
            New Here?{" "}
            <a
              onClick={() => this._updateMode(MODE_SIGNUP)}
              className="link-primary fw-bolder"
            >
              Create an Account
            </a>
          </div>
        </div>
        {this._renderError()}
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">Email</label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="text"
            placeholder="Email"
            name="email"
            autoComplete="off"
            value={email}
            onChange={(e) =>
              this.setState({ email: e.target.value, error: null })
            }
            autoFocus
          />
        </div>
        <div className="fv-row mb-10">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Password
            </label>
            <a
              onClick={() => this._updateMode(MODE_FORGOT_PASSWORD)}
              class="link-primary fs-6 fw-bolder"
              style={{ cursor: "pointer" }}
            >
              Forgot Password ?
            </a>
          </div>
          <input
            className="form-control form-control-lg form-control-solid"
            type="password"
            placeholder="Password"
            name="password"
            autoComplete="off"
            value={password}
            onChange={(e) =>
              this.setState({ password: e.target.value, error: null })
            }
          />
        </div>
        <div className="text-center">
          <button
            type="button"
            className="btn btn-lg btn-primary w-100 mb-5"
            onClick={(e) => this._handleLogInClicked(e)}
            disabled={isLoading}
          >
            {isLoading ? "Loading... " : "Sign In"}
          </button>
        </div>
      </form>
    );
  }

  _onReCAPTCHAChange(value) {
    if (!value) {
      return;
    }

    this.setState({ recaptcha: value });
  }

  _renderSignUp() {
    let {
      first_name,
      last_name,
      job_title,
      company,
      email,
      agree,
      showPassword,
      showConfirmPassword,
      isLoading,
    } = this.state;
    return (
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate="novalidate"
        id="kt_sign_up_form"
      >
        <div className="mb-10 text-center">
          <h1 className="text-dark mb-3">Create an Account</h1>

          <div className="text-gray-400 fw-bold fs-4">
            Already have an account?{" "}
            <a
              onClick={() => this._updateMode(MODE_SIGNIN)}
              className="link-primary fw-bolder"
            >
              Sign in here
            </a>
          </div>
        </div>
        {this._renderError()}
        <div className="row fv-row fv-plugins-icon-container">
          <div className="col-xl-6 mb-2">
            <label className="form-label fw-bolder text-dark fs-6">
              First Name
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="First Name"
              name="first_name"
              value={first_name}
              onChange={(e) =>
                this.setState({ first_name: e.target.value, error: null })
              }
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          <div className="col-xl-6 mb-2">
            <label className="form-label fw-bolder text-dark fs-6">
              Last Name
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Last Name"
              name="last_name"
              value={last_name}
              onChange={(e) =>
                this.setState({ last_name: e.target.value, error: null })
              }
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          <div className="col-xl-6 mb-2">
            <label className="form-label fw-bolder text-dark fs-6">
              Location
            </label>
            <LocationInput
              placeholder="City / Country"
              location={this.state.location}
              onUpdated={(location) => {
                this.setState({
                  location: location,
                });
              }}
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          <div className="col-xl-6 mb-2">
            <label className="form-label fw-bolder text-dark fs-6">Email</label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={(e) =>
                this.setState({ email: e.target.value, error: null })
              }
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>

          <div className="col-xl-6 mb-2">
            <label className="form-label fw-bolder text-dark fs-6">
              Job Title
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Job Title"
              value={job_title}
              onChange={(e) =>
                this.setState({ job_title: e.target.value, error: null })
              }
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
          <div className="col-xl-6 mb-2">
            <label className="form-label fw-bolder text-dark fs-6">
              Company
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              placeholder="Company"
              value={company}
              onChange={(e) =>
                this.setState({ company: e.target.value, error: null })
              }
            />
            <div className="fv-plugins-message-container invalid-feedback" />
          </div>
        </div>
        <div
          className="fv-row fv-plugins-icon-container"
          data-kt-password-meter="true"
        >
          <div className="mb-2">
            <label className="form-label fw-bolder text-dark fs-6">
              Password
            </label>
            <div className="position-relative mb-3">
              <input
                className="form-control form-control-lg form-control-solid"
                type={showPassword ? "text" : "password"}
                placeholder
                name="password"
                autoComplete="off"
                onChange={(e) =>
                  this.setState({ password: e.target.value, error: null })
                }
              />
              <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                data-kt-password-meter-control="visibility"
                onClick={() => this.setState({ showPassword: !showPassword })}
              >
                <i className="bi bi-eye-slash fs-2" />
                <i className="bi bi-eye fs-2 d-none" />
              </span>
            </div>
          </div>
          <div className="text-muted">Use 6 or more characters.</div>
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        <div
          className="mb-10 fv-row fv-plugins-icon-container"
          data-kt-password-meter="true"
        >
          <div className="mb-1">
            <label className="form-label fw-bolder text-dark fs-6">
              Confirm Password
            </label>
            <div className="position-relative mb-3">
              <input
                className="form-control form-control-lg form-control-solid"
                type={showConfirmPassword ? "text" : "password"}
                placeholder
                name="password"
                autoComplete="off"
                onChange={(e) =>
                  this.setState({
                    confirm_password: e.target.value,
                    error: null,
                  })
                }
              />
              <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                data-kt-password-meter-control="visibility"
                onClick={() =>
                  this.setState({ showConfirmPassword: !showConfirmPassword })
                }
              >
                <i className="bi bi-eye-slash fs-2" />
                <i className="bi bi-eye fs-2 d-none" />
              </span>
            </div>
          </div>
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        <div className="fv-row mb-10 fv-plugins-icon-container">
          <label className="form-check form-check-custom form-check-solid form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              name="toc"
              checked={agree}
              onChange={(e) => this.setState({ agree: e.target.checked })}
            />
            <span className="form-check-label fw-bold text-gray-700 fs-6">
              I accept the
              <a
                onClick={() => this.setState({ showTerms: true })}
                className="ms-1 link-primary"
              >
                Terms & Conditions{" "}
              </a>
              and wish to proceed with registration process.
            </span>
          </label>
          <div className="fv-plugins-message-container invalid-feedback" />
        </div>
        <div
          className="fv-row mb-10 fv-plugins-icon-container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReCAPTCHA
            type="image"
            sitekey={window.Api.RecaptchaSiteKey}
            onChange={(value) => this._onReCAPTCHAChange(value)}
          />
        </div>
        <div className="text-center">
          <button
            type="button"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100"
            disabled={isLoading || !agree}
            onClick={(e) => this._signUpPressed(e)}
          >
            {isLoading ? "Loading... " : "Submit"}
          </button>
        </div>
        <div />
      </form>
    );
  }

  _renderForgotPassword() {
    let { email, password, isLoading, showPassword } = this.state;
    return (
      <form
        className="form w-100"
        noValidate="novalidate"
        id="kt_password_reset_form"
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Forgot Password ?</h1>
          <div className="text-gray-400 fw-bold fs-4">
            Enter your email to reset your password.
          </div>
        </div>
        {this._renderError()}
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Email
          </label>
          <input
            className="form-control form-control-lg form-control-solid"
            type="email"
            placeholder="Email"
            name="email"
            value={email}
            onChange={(e) =>
              this.setState({ email: e.target.value, error: null })
            }
          />
        </div>
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="button"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
            disabled={isLoading}
            onClick={(e) => this._handleRequestResetPassword(e)}
          >
            {isLoading ? "Loading... " : "Submit"}
          </button>
          <a
            onClick={() => this._updateMode(MODE_SIGNIN)}
            className="btn btn-lg btn-light-primary fw-bolder"
          >
            Cancel
          </a>
        </div>
      </form>
    );
  }

  _renderResetPassword() {
    let {
      isLoading,
      code,
      showPassword,
      showConfirmPassword,
      confirm_password,
    } = this.state;
    return (
      <form
        className="form w-100"
        noValidate="novalidate"
        id="kt_password_reset_form"
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Reset Password</h1>
          <div className="text-gray-400 fw-bold fs-4">
            A verification code was sent to your email, please enter it below
            along with your new password.
          </div>
        </div>
        {this._renderError()}
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Password
          </label>
          <div className="position-relative mb-3">
            <input
              className="form-control form-control-lg form-control-solid"
              type={showPassword ? "text" : "password"}
              placeholder
              name="password"
              autoComplete="off"
              onChange={(e) =>
                this.setState({ password: e.target.value, error: null })
              }
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
              data-kt-password-meter-control="visibility"
              onClick={() => this.setState({ showPassword: !showPassword })}
            >
              <i className="bi bi-eye-slash fs-2" />
              <i className="bi bi-eye fs-2 d-none" />
            </span>
          </div>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Confirm Password
          </label>
          <div className="position-relative mb-3">
            <input
              className="form-control form-control-lg form-control-solid"
              type={showConfirmPassword ? "text" : "password"}
              placeholder
              name="password"
              autoComplete="off"
              onChange={(e) =>
                this.setState({ confirm_password: e.target.value, error: null })
              }
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
              data-kt-password-meter-control="visibility"
              onClick={() =>
                this.setState({ showConfirmPassword: !showConfirmPassword })
              }
            >
              <i className="bi bi-eye-slash fs-2" />
              <i className="bi bi-eye fs-2 d-none" />
            </span>
          </div>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            Code
          </label>
          <input
            className="form-control form-control-solid"
            type="text"
            placeholder="Code"
            autoComplete="off"
            value={code}
            onChange={(e) =>
              this.setState({ code: e.target.value, error: null })
            }
          />
        </div>
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="button"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
            disabled={isLoading}
            onClick={(e) => this._handleResetPassword(e)}
          >
            {isLoading ? "Loading... " : "Submit"}
          </button>
          <a
            onClick={() => this.setState({ mode: MODE_SIGNIN })}
            className="btn btn-lg btn-light-primary fw-bolder"
          >
            Cancel
          </a>
        </div>
      </form>
    );
  }
  render() {
    let { mode } = this.state;
    return (
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div
            className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative"
            style={{
              background:
                "linear-gradient(0deg, rgba(40,54,255,1) 0%, rgba(8,8,116,1) 100%)",
            }}
          >
            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px h-100">
              <div
                className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20"
                style={{ justifyContent: "center" }}
              >
                <a href="#" className="py-9 mb-5">
                  <img
                    alt="Logo"
                    src="assets/media/logos/avolon-logo.png"
                    className="h-60px ps-10"
                  />
                </a>
                <h1
                  className="fw-bolder fs-2qx pb-5 pb-md-10"
                  style={{ color: "#FFF" }}
                >
                  Avolon Investor Login
                </h1>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div
                className="w-lg-500px p-10 p-lg-15 mx-auto"
                style={{
                  display: mode === MODE_SIGNIN ? "block" : "none",
                }}
              >
                {this._renderSignIn()}
              </div>
              <div
                className="w-lg-500px p-10 p-lg-15 mx-auto"
                style={{
                  display: mode === MODE_SIGNUP ? "block" : "none",
                }}
              >
                {this._renderSignUp()}
              </div>
              <div
                className="w-lg-500px p-10 p-lg-15 mx-auto"
                style={{
                  display: mode === MODE_FORGOT_PASSWORD ? "block" : "none",
                }}
              >
                {this._renderForgotPassword()}
              </div>
              <div
                className="w-lg-500px p-10 p-lg-15 mx-auto"
                style={{
                  display: mode === MODE_RESET_PASSWORD ? "block" : "none",
                }}
              >
                {this._renderResetPassword()}
              </div>
            </div>
            <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
              <div className="d-flex flex-center fw-bold fs-6">
                <a
                  onClick={() => {
                    window.location.href =
                      env.REACT_APP_WEBSITE_DOMAIN + "/about-us";
                  }}
                  style={{ cursor: "pointer" }}
                  className="text-muted text-hover-primary px-2"
                  target="_blank"
                >
                  About
                </a>
                <a
                  onClick={() => {
                    window.location.href =
                      env.REACT_APP_WEBSITE_DOMAIN + "/contact";
                  }}
                  style={{ cursor: "pointer" }}
                  className="text-muted text-hover-primary px-2"
                  target="_blank"
                >
                  Support
                </a>
              </div>
            </div>
          </div>
        </div>
        <TermsModal
          show={this.state.showTerms}
          onHide={() => this.setState({ showTerms: false })}
        />
      </div>
    );
  }
}
