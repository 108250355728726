import React, { Component } from 'react'

import YearPicker from '../common/YearPicker'
import FileItem from '../FileItem'

import moment from 'moment'

import Backend from '../../../utils/Backend'
import Config from '../../../utils/Config'
import Notify from '../../../utils/Notify'

export default class Results extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: Config.getStartYear(true),
      quarterlyData: [],
      isQuarterlyLoading: false,
    }
    this._loadQuarterlyReports()
  }

  _loadQuarterlyReports() {
    let { selectedDate } = this.state
    this.setState({ isQuarterlyLoading: true })
    Backend.getReports('investor_results', selectedDate)
      .then((response) => {
        this.setState({ quarterlyData: response, isQuarterlyLoading: false })
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isQuarterlyLoading: false })
      })
  }

  _renderQuarterlyLoading() {
    if (!this.state.isQuarterlyLoading) return null
    return (
      <div class="container">
        <div class="text-center mb-12">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  _renderNoQuarterlyResults() {
    let { quarterlyData, isQuarterlyLoading } = this.state
    if (quarterlyData.length <= 0 && !isQuarterlyLoading) {
      return (
        <div className="text-center mb-12">
          <h6
            className="mb-5"
            id="credit-ratings"
            data-kt-scroll-offset="{default: 100, lg: 150}"
          >
            No quarterly reports available
          </h6>
        </div>
      )
    }
  }

  _renderReportedToolbar() {
    return (
      <YearPicker
        startYear={Config.getStartYear(true)}
        activeItem={this.state.selectedDate}
        onItemClicked={(item) =>
          this.setState({ selectedDate: item }, () =>
            this._loadQuarterlyReports()
          )
        }
      />
    )
  }

  _renderQuarterlyRows() {
    let { quarterlyData, isLoading } = this.state
    if (quarterlyData.length <= 0 && !isLoading) {
      return null
    }
    return quarterlyData.map((item) => {
      return (
        <tr>
          <td>
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                  {item.title}
                </span>
                <span className="text-muted fw-bold text-muted d-block fs-7">
                  {moment(item.published_at).format('Do MMM YYYY')}
                </span>
              </div>
            </div>
          </td>
          <td>
            <FileItem url={item.release_url} slug={item.slug} />
          </td>
          <td>
            <FileItem url={item.presentation_url} slug={item.slug} />
          </td>
          <td>
            <FileItem url={item.webcast_url} slug={item.slug} />
          </td>
          <td>
            <FileItem url={item.transcript_url} slug={item.slug} />
          </td>
          <td>
            <FileItem url={item.event_detail_url} slug={item.slug} />
          </td>
        </tr>
      )
    })
  }

  render() {
    return (
      <div className="py-10 py-lg-20 bg-light">
        <div className="container">
          <div className="text-center mb-12">
            <h2
              className="mb-5"
              id="results"
              data-kt-scroll-offset="{default: 100, lg: 150}"
            >
              REPORTED RESULTS
            </h2>
            <hr />
          </div>
          <div className="card mb-5 mb-xl-8">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-1 mb-1">
                  Quarterly Results
                </span>
                <span className="text-muted mt-1 fw-bold fs-7"></span>
              </h3>
              <div className="card-toolbar">
                <div
                  className="d-flex justify-content-end"
                  data-kt-user-table-toolbar="base"
                >
                  {this._renderReportedToolbar()}
                </div>
              </div>
            </div>
            <div className="card-body py-3">
              <div className="table-responsive">
                <table className="table align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fw-bolder text-muted bg-light">
                      <th className="ps-4 min-w-325px rounded-start">Title</th>
                      <th className="min-w-150px">Release</th>
                      <th className="min-w-150px">Presentation</th>
                      <th className="min-w-150px">Webcast</th>
                      <th className="min-w-150px">Transcript</th>
                      <th className="min-w-150px">Event Detail</th>
                    </tr>
                  </thead>
                  <tbody>{this._renderQuarterlyRows()}</tbody>
                </table>
                {this._renderQuarterlyLoading()}
                {this._renderNoQuarterlyResults()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
